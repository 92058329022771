"use client"
import { motion } from "framer-motion"
import { Link } from "react-scroll"

const Hero = () => {
  return (
    <motion.section
      id="hero"
      className="relative flex flex-col items-center justify-center min-h-screen px-4 overflow-hidden text-center sm:px-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      {/* Fondo con gradiente */}
      <div className="absolute inset-0 z-0 bg-gradient-to-br from-pastelLavender to-pastelCream"></div>

      {/* Estrellas decorativas - simples y no distractivas */}
      {[...Array(8)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute z-0 w-1 h-1 bg-white rounded-full"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.7, 1, 0.7],
          }}
          transition={{
            duration: 2 + Math.random() * 3,
            repeat: Number.POSITIVE_INFINITY,
            repeatType: "reverse",
            delay: Math.random() * 2,
          }}
        />
      ))}

      {/* Contenido principal */}
      <div className="container relative z-10 mx-auto">
        {/* Versión móvil - Estructura vertical */}
        <div className="flex flex-col items-center md:hidden">
          {/* Texto primero en móvil */}
          <motion.div
            className="mb-8 space-y-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <motion.h1
              className="text-4xl font-bold leading-tight sm:text-5xl text-primary"
              style={{ textWrap: "balance" }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              Bienvenidos a{" "}
              <span className="relative inline-block">
                KAIOBA
                <motion.span
                  className="absolute left-0 w-full h-1 rounded-full -bottom-2 bg-primary"
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{ duration: 0.8, delay: 1 }}
                />
              </span>
            </motion.h1>

            <motion.p
              className="max-w-md mx-auto text-base sm:text-lg text-darkGreen"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              Conectamos creatividad y tecnología para crear experiencias únicas.
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.9 }}
            >
              <Link
                to="services"
                smooth={true}
                duration={500}
                offset={-80}
                className="inline-block px-8 py-3 mt-4 text-white transition-colors duration-300 rounded-full shadow-md bg-primary hover:bg-darkGreen hover:shadow-lg"
                aria-label="Conócenos"
              >
                Conócenos
              </Link>
            </motion.div>
          </motion.div>

          {/* Michi después del texto en móvil */}
          <motion.div
            className="relative z-20 mt-4" // Aumentado z-index
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <motion.img
              src="/assets/MICHI_ESPACIAL_A_COLOR.png"
              alt="Michi Espacial"
              className="w-40 sm:w-48"
              animate={{
                y: [0, -10, 0],
                rotate: [0, 2, 0, -2, 0],
              }}
              transition={{
                y: { repeat: Number.POSITIVE_INFINITY, duration: 3, ease: "easeInOut" },
                rotate: { repeat: Number.POSITIVE_INFINITY, duration: 6, ease: "easeInOut" },
              }}
              style={{ filter: "drop-shadow(0 8px 24px rgba(0,0,0,0.2))" }}
              loading="lazy"
            />
          </motion.div>
        </div>

        {/* Versión desktop - Estructura centralizada */}
        <div className="items-center justify-center hidden md:flex">
          <div className="flex items-center max-w-5xl">
            {/* Michi Espacial a la izquierda */}
            <motion.div
              className="relative z-20 flex justify-center w-1/3" // Aumentado z-index
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.5 }}
            >
              <motion.img
                src="/assets/MICHI_ESPACIAL_A_COLOR.png"
                alt="Michi Espacial"
                className="w-48 lg:w-56 xl:w-64"
                animate={{
                  y: [0, -15, 0],
                  rotate: [0, 3, 0, -3, 0],
                }}
                transition={{
                  y: { repeat: Number.POSITIVE_INFINITY, duration: 3, ease: "easeInOut" },
                  rotate: { repeat: Number.POSITIVE_INFINITY, duration: 6, ease: "easeInOut" },
                }}
                style={{ filter: "drop-shadow(0 8px 24px rgba(0,0,0,0.2))" }}
                loading="lazy"
              />
            </motion.div>

            {/* Texto a la derecha */}
            <motion.div
              className="w-2/3 pl-8 space-y-6 text-left"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <motion.h1
                className="text-4xl font-bold leading-tight lg:text-5xl xl:text-6xl text-primary"
                style={{ textWrap: "balance" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                Bienvenidos a{" "}
                <span className="relative inline-block">
                  KAIOBA
                  <motion.span
                    className="absolute left-0 w-full h-1 rounded-full -bottom-2 bg-primary"
                    initial={{ scaleX: 0 }}
                    animate={{ scaleX: 1 }}
                    transition={{ duration: 0.8, delay: 1 }}
                  />
                </span>
              </motion.h1>

              <motion.p
                className="max-w-md text-lg xl:text-xl text-darkGreen"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                Conectamos creatividad y tecnología para crear experiencias únicas.
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.9 }}
              >
                <Link
                  to="services"
                  smooth={true}
                  duration={500}
                  offset={-80}
                  className="inline-block px-8 py-3 mt-4 text-white transition-colors duration-300 rounded-full shadow-md bg-primary hover:bg-darkGreen hover:shadow-lg"
                  aria-label="Conócenos"
                >
                  Conócenos
                </Link>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Estrellas flotantes reposicionadas para evitar superposición */}
      <motion.img
        src="/assets/ESTRELLA_KAIOBA.png"
        alt="Estrella decorativa"
        className="absolute z-10 w-8 h-8 top-1/4 right-1/4" // Cambiado a la derecha
        animate={{
          rotate: [0, 360],
          opacity: [0.7, 1, 0.7],
        }}
        transition={{
          rotate: { repeat: Number.POSITIVE_INFINITY, duration: 15, ease: "linear" },
          opacity: { repeat: Number.POSITIVE_INFINITY, duration: 2, ease: "easeInOut" },
        }}
      />

      <motion.img
        src="/assets/ESTRELLA_KAIOBA.png"
        alt="Estrella decorativa"
        className="absolute z-10 w-6 h-6 bottom-1/4 right-1/3" // Cambiado a la derecha
        animate={{
          rotate: [0, -360],
          opacity: [0.6, 0.9, 0.6],
        }}
        transition={{
          rotate: { repeat: Number.POSITIVE_INFINITY, duration: 20, ease: "linear" },
          opacity: { repeat: Number.POSITIVE_INFINITY, duration: 3, ease: "easeInOut" },
        }}
      />

      {/* Scroll indicator */}
      <motion.div
        className="absolute z-10 hidden transform -translate-x-1/2 bottom-8 left-1/2 sm:block"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 0.5 }}
      >
        <motion.div
          className="flex justify-center w-6 h-10 p-1 border-2 rounded-full border-primary"
          animate={{ y: [0, 5, 0] }}
          transition={{ repeat: Number.POSITIVE_INFINITY, duration: 1.5 }}
        >
          <motion.div
            className="w-1 h-2 rounded-full bg-primary"
            animate={{ y: [0, 6, 0] }}
            transition={{ repeat: Number.POSITIVE_INFINITY, duration: 1.5 }}
          />
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default Hero



