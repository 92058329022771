import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Manteniendo las categorías originales de los proyectos
const projects = [
  {
    title: "Alpha Courier",
    category: "Diseños y gestión de Redes",
    image: "/portfolio/alpha_courier.webp",
  },
  {
    title: "Pangolin",
    category: "Logo, Ilustraciones y Diseños personalizados",
    image: "/portfolio/pangolin.webp",
  },
  {
    title: "Escribanía Magali Benítez",
    category: "Diseños de cartelería, merch y Página web",
    image: "/portfolio/escribania_benitez.webp",
  },
  {
    title: "Diori",
    category: "Logo, Diseños y Videos personalizados",
    image: "/portfolio/diori.webp",
  },
  {
    title: "Crearte",
    category: "Logo y gestión de Redes",
    image: "/portfolio/crearte.webp",
  },
  {
    title: "Gamepoint",
    category: "Logo, Diseños y gestión de Redes",
    image: "/portfolio/gamepoint.webp",
  },
];

// Extraer categorías únicas de los proyectos
const categories = [
  "Todos",
  ...Array.from(new Set(projects.map(project => project.category)))
];

// Mapeo de categorías a servicios para mostrar la relación
const categoryToService = {
  "Diseños y gestión de Redes": "Gestión de Redes Sociales",
  "Logo, Ilustraciones y Diseños personalizados": "Diseño de Logos y Contenido",
  "Diseños de cartelería, merch y Página web": "Diseño de Contenido y Páginas Web",
  "Logo, Diseños y Videos personalizados": "Diseño de Contenido",
  "Logo y gestión de Redes": "Diseño de Logos y Gestión de Redes Sociales",
  "Logo, Diseños y gestión de Redes": "Diseño de Logos y Gestión de Redes Sociales"
};

const Portfolio = () => {
  const [activeCategory, setActiveCategory] = useState("Todos");
  const [hoveredProject, setHoveredProject] = useState(null);

  // Filtrar proyectos según la categoría seleccionada
  const filteredProjects = activeCategory === "Todos" 
    ? projects 
    : projects.filter(project => project.category === activeCategory);

  return (
    <motion.section
      id="portfolio"
      className="relative py-20 overflow-hidden bg-gradient-to-br from-pastelLavender to-pastelCream"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      {/* Elementos decorativos */}
      <div className="absolute top-0 right-0 w-64 h-64 -mt-32 -mr-32 rounded-full bg-pastelYellow blur-3xl opacity-20"></div>
      <div className="absolute bottom-0 left-0 w-64 h-64 -mb-32 -ml-32 rounded-full bg-pastelLavender blur-3xl opacity-20"></div>
      
      <div className="container relative z-10 px-4 mx-auto">
        <motion.div 
          className="mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="mb-4 text-4xl font-bold sm:text-5xl text-primary">
            Portafolio
          </h2>
          <div className="w-24 h-1 mx-auto mb-6 rounded-full bg-primary"></div>
          <p className="max-w-2xl mx-auto text-gray-700">
            Explora algunos de nuestros proyectos más destacados y descubre cómo hemos ayudado a nuestros clientes a alcanzar sus objetivos.
          </p>
        </motion.div>
        
        {/* Filtros de categoría - Manteniendo las categorías originales */}
        <motion.div 
          className="flex flex-wrap justify-center gap-3 mb-10"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {categories.map((category, index) => (
            <motion.button
              key={index}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                activeCategory === category
                  ? "bg-primary text-white shadow-md"
                  : "bg-white/80 text-gray-700 hover:bg-white"
              }`}
              onClick={() => setActiveCategory(category)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 * index }}
            >
              {category}
            </motion.button>
          ))}
        </motion.div>
        
        {/* Grid de proyectos */}
        <motion.div 
          className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
          layout
        >
          <AnimatePresence mode="wait">
            {filteredProjects.length > 0 ? (
              filteredProjects.map((project, index) => (
                <motion.div
                  key={project.title}
                  className="relative overflow-hidden bg-white shadow-lg rounded-xl"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.4 }}
                  layout
                  onMouseEnter={() => setHoveredProject(project.title)}
                  onMouseLeave={() => setHoveredProject(null)}
                  whileHover={{ y: -5 }}
                >
                  <div className="relative aspect-[4/3] overflow-hidden">
                    <motion.img
                      src={project.image}
                      alt={project.title}
                      className="object-cover w-full h-full"
                      initial={{ scale: 1 }}
                      animate={{ 
                        scale: hoveredProject === project.title ? 1.1 : 1 
                      }}
                      transition={{ duration: 0.4 }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `https://via.placeholder.com/400x300?text=${project.title}`;
                      }}
                    />
                    
                    {/* Overlay con información */}
                    <motion.div 
                      className="absolute inset-0 flex flex-col justify-end p-6 text-white bg-gradient-to-t from-black/80 via-black/50 to-transparent"
                      initial={{ opacity: 0 }}
                      animate={{ 
                        opacity: hoveredProject === project.title ? 1 : 0 
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <motion.h3 
                        className="mb-2 text-2xl font-bold"
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ 
                          y: hoveredProject === project.title ? 0 : 20,
                          opacity: hoveredProject === project.title ? 1 : 0
                        }}
                        transition={{ duration: 0.3, delay: 0.1 }}
                      >
                        {project.title}
                      </motion.h3>
                      <motion.p 
                        className="text-sm text-gray-200"
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ 
                          y: hoveredProject === project.title ? 0 : 20,
                          opacity: hoveredProject === project.title ? 1 : 0
                        }}
                        transition={{ duration: 0.3, delay: 0.2 }}
                      >
                        {project.category}
                      </motion.p>
                      {categoryToService[project.category] && (
                        <motion.p 
                          className="mt-2 text-xs text-primary-light"
                          initial={{ y: 20, opacity: 0 }}
                          animate={{ 
                            y: hoveredProject === project.title ? 0 : 20,
                            opacity: hoveredProject === project.title ? 1 : 0
                          }}
                          transition={{ duration: 0.3, delay: 0.3 }}
                        >
                          Servicio: {categoryToService[project.category]}
                        </motion.p>
                      )}
                    </motion.div>
                  </div>
                  
                  {/* Información visible siempre (para móviles) */}
                  <div className="p-4 sm:hidden">
                    <h3 className="text-xl font-bold text-primary">{project.title}</h3>
                    <p className="text-sm text-gray-600">{project.category}</p>
                  </div>
                </motion.div>
              ))
            ) : (
              // Mensaje cuando no hay proyectos en la categoría seleccionada
              <motion.div 
                className="py-12 text-center col-span-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <p className="text-lg text-gray-600">
                  Aún no tenemos proyectos en esta categoría. ¡Pronto agregaremos más!
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        
        {/* CTA */}
        <motion.div 
          className="mt-12 text-center"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <motion.a
            href="#contact"
            className="inline-block px-8 py-3 text-white transition-all duration-300 rounded-full shadow-md bg-primary hover:shadow-lg hover:bg-darkGreen"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            ¿Tienes un proyecto en mente?
          </motion.a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Portfolio;
