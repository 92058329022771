import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { motion, AnimatePresence } from "framer-motion";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Detectar scroll para cambiar estilo del navbar
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navLinks = [
    { name: "Inicio", to: "hero" },
    { name: "Servicios", to: "services" },
    { name: "Portafolio", to: "portfolio" },
    { name: "Contacto", to: "contact" },
  ];

  const menuVariants = {
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
        when: "afterChildren",
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    },
    open: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.05,
        staggerDirection: 1
      }
    }
  };

  const itemVariants = {
    closed: { opacity: 0, y: -10 },
    open: { opacity: 1, y: 0 }
  };

  return (
    <motion.nav 
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        scrolled 
          ? "bg-white shadow-md py-2" 
          : "bg-white/90 backdrop-blur-sm py-4"
      }`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container flex items-center justify-between px-6 mx-auto">
        {/* Logo */}
        <motion.a 
          href="#hero" 
          className="flex items-center"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <img
            src="/assets/LOGO_VERDE_FONDOS_CLAROS.png"
            alt="Kaioba Logo"
            className="w-24 h-auto"
          />
        </motion.a>

        {/* Menú Desktop */}
        <ul className="hidden space-x-8 text-lg font-medium md:flex">
          {navLinks.map((link, index) => (
            <motion.li key={index}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Link
                to={link.to}
                smooth={true}
                duration={500}
                offset={-80} // Ajuste para compensar la altura del navbar
                className="relative px-1 py-2 transition-colors duration-300 cursor-pointer text-darkGreen hover:text-primary"
                activeClass="text-primary"
                spy={true}
              >
                <span>{link.name}</span>
                <motion.span 
                  className="absolute bottom-0 left-0 w-full h-0.5 bg-primary rounded-full"
                  initial={{ scaleX: 0 }}
                  whileHover={{ scaleX: 1 }}
                  transition={{ duration: 0.3 }}
                />
              </Link>
            </motion.li>
          ))}
        </ul>

        {/* Botón de contacto (solo en desktop) */}
        <motion.div 
          className="hidden md:block"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="px-5 py-2 text-white transition-colors duration-300 rounded-full cursor-pointer bg-primary hover:bg-darkGreen"
          >
            Contáctanos
          </Link>
        </motion.div>

        {/* Botón Hamburguesa para Móvil */}
        <motion.button
          className="md:hidden focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
          whileTap={{ scale: 0.9 }}
          aria-label={isOpen ? "Cerrar menú" : "Abrir menú"}
        >
          <div className="flex items-center justify-center w-8 h-8 text-darkGreen">
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </div>
        </motion.button>
      </div>

      {/* Menú Mobile */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="bg-white border-t border-gray-100 shadow-lg md:hidden"
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            <ul className="flex flex-col items-center py-4">
              {navLinks.map((link, index) => (
                <motion.li 
                  key={index}
                  variants={itemVariants}
                  className="w-full"
                >
                  <Link
                    to={link.to}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="block px-6 py-3 text-center transition-colors duration-300 text-darkGreen hover:bg-gray-50 hover:text-primary"
                    onClick={() => setIsOpen(false)}
                  >
                    {link.name}
                  </Link>
                </motion.li>
              ))}
              <motion.li variants={itemVariants} className="w-full px-6 mt-4">
                <Link
                  to="contact"
                  smooth={true}
                  duration={500}
                  className="block w-full py-3 text-center text-white transition-colors duration-300 rounded-full bg-primary hover:bg-darkGreen"
                  onClick={() => setIsOpen(false)}
                >
                  Contáctanos
                </Link>
              </motion.li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

export default Navbar;
