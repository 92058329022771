"use client"
import { motion } from "framer-motion"
import { Link } from "react-scroll"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  const socialLinks = [
    {
      name: "Instagram",
      url: "https://www.instagram.com/somoskaioba",
      icon: "/assets/ig.png",
    },
    {
      name: "Facebook",
      url: "https://www.facebook.com/somoskaioba",
      icon: "/assets/fb.png",
    },
    {
      name: "Whatsapp",
      url: "https://wa.me/595984338469",
      icon: "/assets/wsp.png",
    },
    {
      name: "Correo",
      url: "mailto:descubre@kaioba.com",
      icon: "/assets/correo.png",
    },
  ]

  const navLinks = [
    { name: "Inicio", to: "hero" },
    { name: "Servicios", to: "services" },
    { name: "Portafolio", to: "portfolio" },
    { name: "Contacto", to: "contact" },
  ]

  return (
    <footer className="relative py-12 overflow-hidden bg-darkGreen text-pastelCream">
      {/* Fondo con marca de agua */}
      <div className="absolute inset-0 overflow-hidden">
        <img
          src="/assets/MARCA_DE_AGUA.png"
          alt="Marca de Agua"
          className="absolute inset-0 object-cover w-full h-full pointer-events-none opacity-10"
        />
      </div>

      {/* Elementos Decorativos */}
      <motion.div
        className="absolute top-0 left-0 w-32 h-32 rounded-full bg-pastelYellow blur-3xl opacity-10"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.1, 0.15, 0.1],
        }}
        transition={{
          duration: 8,
          repeat: Number.POSITIVE_INFINITY,
          repeatType: "reverse",
        }}
      ></motion.div>

      <motion.div
        className="absolute bottom-0 right-0 w-40 h-40 rounded-full bg-pastelLavender blur-3xl opacity-10"
        animate={{
          scale: [1, 1.3, 1],
          opacity: [0.1, 0.2, 0.1],
        }}
        transition={{
          duration: 10,
          repeat: Number.POSITIVE_INFINITY,
          repeatType: "reverse",
          delay: 1,
        }}
      ></motion.div>

      {/* Contenido */}
      <div className="container relative z-10 px-4 mx-auto">
        <div className="grid grid-cols-1 gap-8 mb-8 md:grid-cols-3">
          {/* Columna 1: Logo e información */}
          <div className="text-center md:text-left">
            <motion.div
              className="mb-6"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <img
                src="/assets/LOGO_CREMA_FONDOS_CLAROS.png"
                alt="Kaioba Logo"
                className="mx-auto md:mx-0 w-28 md:w-36"
              />
            </motion.div>
            <motion.p
              className="max-w-xs mx-auto text-sm md:text-base opacity-80 md:mx-0"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 0.8 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Conectamos creatividad y tecnología para crear experiencias únicas para tu marca.
            </motion.p>
          </div>

          {/* Columna 2: Enlaces rápidos */}
          <div className="text-center md:text-left">
            <motion.h3
              className="mb-4 text-lg font-bold"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              Enlaces rápidos
            </motion.h3>
            <motion.ul
              className="space-y-2"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {navLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.to}
                    smooth={true}
                    duration={500}
                    className="transition-all duration-300 cursor-pointer opacity-80 hover:opacity-100 hover:text-white"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </motion.ul>
          </div>

          {/* Columna 3: Contacto */}
          <div className="text-center md:text-left">
            <motion.h3
              className="mb-4 text-lg font-bold"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              Síguenos
            </motion.h3>
            <motion.div
              className="flex justify-center gap-6 md:justify-start"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {socialLinks.map((social, index) => (
                <motion.a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition-transform duration-300 hover:scale-125"
                  aria-label={social.name}
                  whileHover={{ scale: 1.2, rotate: 5 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <img src={social.icon || "/placeholder.svg"} alt={social.name} className="w-6 h-6 md:w-8 md:h-8" />
                </motion.a>
              ))}
            </motion.div>
          </div>
        </div>

        {/* Línea divisoria */}
        <motion.div
          className="h-px my-6 bg-pastelCream/20"
          initial={{ scaleX: 0 }}
          whileInView={{ scaleX: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        ></motion.div>

        {/* Copyright */}
        <motion.div
          className="text-sm text-center opacity-70"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 0.7 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <p>© {currentYear} Kaioba. Todos los derechos reservados.</p>
        </motion.div>
      </div>
    </footer>
  )
}

export default Footer

