"use client"

import { useState } from "react"
import { motion } from "framer-motion"

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })

  const [isSent, setIsSent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    // Simulamos el envío del formulario
    setTimeout(() => {
      setIsSubmitting(false)
      setIsSent(true)
    }, 1500)
  }

  return (
    <motion.section
      id="contact"
      className="relative py-20 overflow-hidden bg-gradient-to-br from-pastelLavender to-pastelCream"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      {/* Elementos decorativos */}
      <div className="absolute top-0 right-0 w-64 h-64 -mt-32 -mr-32 rounded-full bg-pastelYellow blur-3xl opacity-20"></div>
      <div className="absolute bottom-0 left-0 w-64 h-64 -mb-32 -ml-32 rounded-full bg-pastelLavender blur-3xl opacity-20"></div>

      <div className="container relative z-10 px-4 mx-auto">
        <motion.div
          className="mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="mb-4 text-4xl font-bold sm:text-5xl text-primary">Contáctanos</h2>
          <div className="w-24 h-1 mx-auto mb-6 rounded-full bg-primary"></div>
          <p className="max-w-2xl mx-auto text-gray-700">
            Estamos listos para ayudarte con tu próximo proyecto. Cuéntanos tus ideas y nos pondremos en contacto
            contigo.
          </p>
        </motion.div>

        <div className="max-w-2xl mx-auto">
          {isSent ? (
            <motion.div
              className="p-8 text-center bg-white shadow-xl rounded-2xl"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <motion.div
                className="flex items-center justify-center w-20 h-20 mx-auto mb-6 bg-green-100 rounded-full"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2, type: "spring" }}
              >
                <svg
                  className="w-10 h-10 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
              </motion.div>
              <h3 className="mb-4 text-2xl font-bold text-primary">¡Gracias por tu mensaje!</h3>
              <p className="mb-6 text-gray-700">Nos pondremos en contacto contigo a la brevedad.</p>
              <motion.button
                onClick={() => setIsSent(false)}
                className="px-6 py-2 text-white transition-colors duration-300 rounded-full bg-primary hover:bg-darkGreen"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Enviar otro mensaje
              </motion.button>
            </motion.div>
          ) : (
            <motion.div
              className="overflow-hidden bg-white shadow-xl rounded-2xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="p-8">
                <form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  className="space-y-6"
                  action="/thank-you-page.html"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <p hidden>
                    <label>
                      No llenar este campo: <input name="bot-field" />
                    </label>
                  </p>

                  <div>
                    <label htmlFor="name" className="block mb-1 text-sm font-medium text-gray-700">
                      Nombre
                    </label>
                    <motion.input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Tu nombre"
                      className="w-full p-3 transition-all duration-300 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                      required
                      onChange={handleChange}
                      value={formData.name}
                      whileFocus={{ scale: 1.01 }}
                    />
                  </div>

                  <div>
                    <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-700">
                      Correo electrónico
                    </label>
                    <motion.input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Tu correo"
                      className="w-full p-3 transition-all duration-300 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                      required
                      onChange={handleChange}
                      value={formData.email}
                      whileFocus={{ scale: 1.01 }}
                    />
                  </div>

                  <div>
                    <label htmlFor="message" className="block mb-1 text-sm font-medium text-gray-700">
                      Mensaje
                    </label>
                    <motion.textarea
                      id="message"
                      name="message"
                      placeholder="¿Qué proyecto tenes en mente? También podes dejarnos tu número y nos contactamos contigo."
                      rows={5}
                      className="w-full p-3 transition-all duration-300 border border-gray-300 rounded-lg resize-none focus:ring-2 focus:ring-primary focus:border-transparent"
                      required
                      onChange={handleChange}
                      value={formData.message}
                      whileFocus={{ scale: 1.01 }}
                    />
                  </div>

                  <motion.button
                    type="submit"
                    className="flex items-center justify-center w-full px-6 py-3 text-white transition-colors duration-300 rounded-full bg-primary hover:bg-darkGreen"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <svg
                          className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Enviando...
                      </>
                    ) : (
                      "Enviar mensaje"
                    )}
                  </motion.button>
                </form>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </motion.section>
  )
}

export default Contact

