"use client"
import { motion } from "framer-motion"
import {
  SparklesIcon,
  GlobeAltIcon,
  CogIcon,
  ChartBarIcon,
  ChatBubbleBottomCenterTextIcon,
  PencilIcon,
} from "@heroicons/react/24/outline"

const Services = () => {
  const services = [
    {
      title: "Diseño de Logos",
      description: "Logos únicos que reflejan la identidad de tu negocio.",
      icon: <SparklesIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Diseño de Contenido",
      description: "Creamos tus flyers e ilustraciones para acompañar las necesidades visuales de tu marca.",
      icon: <PencilIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Páginas Web",
      description: "Desarrollo de sitios modernos y responsivos.",
      icon: <GlobeAltIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Software a Medida",
      description: "Soluciones personalizadas para optimizar procesos.",
      icon: <CogIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Gestión de Redes Sociales",
      description: "Estrategias efectivas para conectar con tu audiencia.",
      icon: <ChatBubbleBottomCenterTextIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Publicidad Digital",
      description: "Campañas que impactan y generan resultados.",
      icon: <ChartBarIcon className="w-14 h-14 text-primary" />,
    },
  ]

  // Variantes para animaciones
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  }

  return (
    <motion.section
      id="services"
      className="py-20 bg-gradient-to-br from-pastelLavender to-pastelCream"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <div className="container px-4 mx-auto">
        <motion.div
          className="mb-16 text-center"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="mb-4 text-4xl font-bold text-center sm:text-5xl text-primary">Nuestros Servicios</h2>
          <div className="w-24 h-1 mx-auto mb-6 rounded-full bg-primary"></div>
          <p className="max-w-2xl mx-auto text-gray-600">
            Soluciones creativas y tecnológicas para impulsar tu negocio y destacar en el mercado digital.
          </p>
        </motion.div>

        <motion.div
          className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="relative p-8 overflow-hidden transition duration-300 transform bg-white shadow-lg rounded-xl hover:shadow-2xl hover:-translate-y-2 group"
              variants={itemVariants}
              whileHover={{
                scale: 1.03,
                boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
              }}
            >
              {/* Decorative elements */}
              <div className="absolute top-0 right-0 z-0 w-24 h-24 -mt-12 -mr-12 rounded-full bg-primary/5"></div>
              <div className="absolute bottom-0 left-0 z-0 w-16 h-16 -mb-8 -ml-8 rounded-full bg-primary/5"></div>

              {/* Icon container with animation */}
              <motion.div
                className="relative z-10 flex justify-center mb-6"
                whileHover={{ rotate: [0, -10, 10, -10, 0] }}
                transition={{ duration: 0.5 }}
              >
                <div className="p-4 transition-colors duration-300 rounded-full bg-primary/10 group-hover:bg-primary/20">
                  {service.icon}
                </div>
              </motion.div>

              {/* Content */}
              <div className="relative z-10">
                <h3 className="mb-3 text-2xl font-bold text-center transition-colors duration-300 text-primary group-hover:text-primaryDark">
                  {service.title}
                </h3>
                <p className="text-center text-gray-600">{service.description}</p>
              </div>

              {/* Bottom accent line with animation */}
              <motion.div
                className="absolute bottom-0 left-0 right-0 h-1 transition-transform duration-300 origin-left transform scale-x-0 bg-primary group-hover:scale-x-100"
                initial={{ scaleX: 0 }}
                whileHover={{ scaleX: 1 }}
              />

              {/* Hover overlay for depth effect */}
              <div className="absolute inset-0 z-0 transition-opacity duration-300 opacity-0 pointer-events-none bg-gradient-to-tr from-primary/5 to-transparent group-hover:opacity-100"></div>
            </motion.div>
          ))}
        </motion.div>

        {/* Call to action */}
        <motion.div
          className="mt-16 text-center"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <motion.a
            href="#contact"
            className="inline-block px-8 py-3 font-medium text-white transition-all duration-300 rounded-full shadow-md bg-primary hover:shadow-lg hover:bg-primaryDark"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            ¿Necesitas alguno de nuestros servicios?
          </motion.a>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default Services

