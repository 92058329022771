"use client"
import { motion } from "framer-motion"

const About = () => {
  return (
    <motion.section
      id="about"
      className="relative py-20 overflow-hidden bg-gradient-to-br from-pastelCream to-pastelLavender text-darkGreen"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      {/* Elementos decorativos */}
      <motion.div
        className="absolute w-64 h-64 rounded-full top-20 right-20 bg-pastelYellow blur-3xl opacity-20"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.2, 0.3, 0.2],
        }}
        transition={{
          duration: 8,
          repeat: Number.POSITIVE_INFINITY,
          repeatType: "reverse",
        }}
      ></motion.div>

      <motion.div
        className="absolute w-64 h-64 rounded-full bottom-20 left-20 bg-pastelLavender blur-3xl opacity-30"
        animate={{
          scale: [1, 1.3, 1],
          opacity: [0.3, 0.4, 0.3],
        }}
        transition={{
          duration: 10,
          repeat: Number.POSITIVE_INFINITY,
          repeatType: "reverse",
          delay: 1,
        }}
      ></motion.div>

      <div className="container relative z-10 px-4 mx-auto">
        <div className="grid items-center grid-cols-1 gap-12 md:grid-cols-2">
          {/* Columna Izquierda: Imagen */}
          <motion.div
            className="relative"
            initial={{ x: -50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7 }}
          >
            <motion.div
              className="relative max-w-sm mx-auto"
              whileHover={{ rotate: [0, -1, 1, -1, 0], scale: 1.02 }}
              transition={{ duration: 1.5 }}
            >
              <img
                src="/assets/COHETE_A_COLOR.png"
                alt="Cohete Kaioba"
                className="relative z-10 w-full max-w-sm mx-auto"
              />

              {/* Efecto de brillo detrás del cohete */}
              <div className="absolute inset-0 z-0 transform scale-75 translate-y-10 rounded-full bg-white/30 blur-xl"></div>
            </motion.div>

            {/* Estrellas animadas */}
            <motion.img
              src="/assets/ESTRELLA_KAIOBA.png"
              alt="Estrella Kaioba"
              className="absolute top-0 z-20 w-16 left-10 md:w-20 opacity-80"
              animate={{ rotate: [0, 360] }}
              transition={{ repeat: Number.POSITIVE_INFINITY, duration: 10, ease: "linear" }}
            />

            <motion.img
              src="/assets/ESTRELLA_KAIOBA.png"
              alt="Estrella Kaioba"
              className="absolute z-20 w-10 bottom-10 right-10 md:w-12 opacity-60"
              animate={{ rotate: [0, -360] }}
              transition={{ repeat: Number.POSITIVE_INFINITY, duration: 15, ease: "linear" }}
            />

            <motion.img
              src="/assets/ESTRELLA_KAIOBA.png"
              alt="Estrella Kaioba"
              className="absolute right-0 z-20 w-8 top-1/2 md:w-10 opacity-40"
              animate={{ rotate: [0, 360] }}
              transition={{ repeat: Number.POSITIVE_INFINITY, duration: 20, ease: "linear" }}
            />
          </motion.div>

          {/* Columna Derecha: Texto */}
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7 }}
          >
            <motion.h2
              className="mb-6 text-4xl font-bold sm:text-5xl text-primary"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              Sobre Nosotros
            </motion.h2>

            <motion.div
              className="w-20 h-1 mb-6 rounded-full bg-primary"
              initial={{ scaleX: 0 }}
              whileInView={{ scaleX: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
            ></motion.div>

            <motion.p
              className="mb-6 text-lg leading-relaxed"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              En <span className="font-semibold text-primary">Kaioba</span>, somos un equipo apasionado que convierte
              ideas en realidades. Nuestro propósito es empoderar a marcas y emprendedores mediante soluciones creativas
              y estratégicas que destacan en un mundo competitivo.
            </motion.p>

            <motion.p
              className="mb-8 text-lg leading-relaxed"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              Con creatividad, tecnología y un enfoque estratégico, ayudamos a que tu marca conecte con su audiencia y
              alcance nuevos horizontes.
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <Link
                to="contact"
                smooth={true}
                duration={500}
                className="inline-block px-8 py-3 text-white transition-all duration-300 rounded-full shadow-md cursor-pointer bg-primary hover:shadow-lg hover:bg-darkGreen"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Contáctanos
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  )
}

// Importar Link de react-scroll
import { Link } from "react-scroll"

export default About

